<footer id="footerID" class="revealed">

  <div class="container">
    <div class="row">
      <div class="col-md-3">

        <h3> <img src="https://img.icons8.com/ios-glyphs/20/ffffff/phone-disconnected.png" style="margin-right: 5px;">
          Call Us</h3>
        <a href="tel://9412418725">(+91) 9412418725</a><br>
        <a href="tel://8126281997">(+91) 8126281997</a>
      </div>
      <div class="col-md-3" style="padding-right: 50px;">
        <h3> <img src="https://img.icons8.com/ios-filled/20/ffffff/address--v1.png" style="margin-right: 5px;"> Address
        </h3>

        <p>
          Badriville Resort<br />
          Nagini Hills, Badrinath Dham,<br />
          Chamoli, Garhwal<br />
          Uttarakhand, 246443
        </p>

        <p>
          Badriville Homestay<br />
          Ashutosh Marg, Singh Dhar Talla<br />
          Joshimath,<br />
          Chamoli Garhwal<br />
          Uttarakhand, 246443
        </p>
      </div>
      <div class="col-md-3 align-center">
        <h3><img src="https://img.icons8.com/material-outlined/25/ffffff/share-2.png" style="padding-right: 5px;">
          Connect Us</h3>
        <a href="https://www.facebook.com/Badriville-Homestay-284952892041524" target="_blank"> <img
            style="padding:2px 5px" src="https://img.icons8.com/ios-glyphs/30/ffffff/facebook-f.png" /></a>
        <a href="https://www.instagram.com/badriville/" target="_blank"> <img style="padding: 2px 5px;"
            src="https://img.icons8.com/material-outlined/24/ffffff/instagram-new--v1.png" /></a>

      </div>
      <div class="col-md-3 align-center">
        <h3> <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="30" viewBox="0 0 172 172"
            style=" fill:#ffffff;">
            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
              stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
              font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g>
                <path
                  d="M162.92633,135.78594v0.20156h-153.85266v-0.20156h0.1075l52.88328,-49.78594l0.16125,-0.17469l23.7743,22.21219l24.64102,-23.02852l0.13773,0.14781l52.02664,50.62914z"
                  fill="#ffffff"></path>
                <path d="M162.92633,36.15023v99.6357h-0.12094l-52.02664,-50.62914l-0.13773,-0.14781z" fill="#ffffff">
                </path>
                <path
                  d="M162.92633,36.0125v0.13773l-52.28531,48.85875l-24.64102,23.02852l-23.7743,-22.21219l-53.15203,-49.66164v-0.15117z"
                  fill="#ffffff"></path>
                <path d="M9.07367,36.16367l53.15203,49.66164l-0.16125,0.17469l-52.88328,49.78594h-0.1075z"
                  fill="#ffffff"></path>
                <path
                  d="M164.94531,35.94531c0,-1.1132 -0.90243,-2.01562 -2.01562,-2.01562h-153.85937c-1.1132,0 -2.01562,0.90243 -2.01562,2.01563v100.10938c0,1.1132 0.90243,2.01563 2.01563,2.01563h153.85938c1.1132,0 2.01563,-0.90243 2.01563,-2.01562zM109.26468,83.50264l-23.26468,21.72575l-71.97864,-67.26745h143.9425zM59.2886,85.84177l-48.20266,45.37978v-90.41791zM62.24485,88.63308l22.38016,20.89531c0.7791,0.71749 1.97992,0.71192 2.75234,-0.01277l23.22941,-21.67771l47.44445,46.20115h-144.0023zM113.55863,85.04527l47.35543,-44.25271v90.33729z"
                  fill="#000000"></path>
              </g>
            </g>
          </svg> Email Us</h3>
        <a href="mailto:vimalpanwar25@gmail.com"> vimalpanwar25@gmail.com</a>


      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div id="social_footer">
          <P style="color: #ffffff;"> @2021 Bardiville , All Rights Reserved </P>
          <P style="color: #ffffff;"> Developed and Maintained By Sukesh U</P>
        </div>

      </div>
    </div><!-- End row -->
  </div><!-- End container -->
</footer><!-- End footer -->

<div id="toTop"></div><!-- Back to top button -->
