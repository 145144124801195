<div class="container"  style="padding-top: 30px;padding-bottom: 30px;">
  <h3 class="mobile-title  mt-remove"><span class="caps-title">Badriville Resort</span></h3>
  <div class="row small-gutters categories_grid">
    <div class="col-sm-12 col-md-6 wow zoomIn" data-wow-delay="0.3s">
      <a href="#" class="more-img-padding speacial-product-img" style="border-radius: 7px;">

        <img src="../../../../assets/img/home_resorts/image_1.jpg" alt=""
          class="img-fluid resort-slides resort-slides-hidden more-img speacial-product-img"
          style="border-radius: 7px; width: 100%;">
        <img src="../../../../assets/img/home_resorts/image_2.jpg" alt=""
          class="img-fluid resort-slides resort-slides-hidden more-img speacial-product-img"
          style="border-radius: 7px; width: 100%;">
        <img src="../../../../assets/img/home_resorts/image_3.jpg" alt=""
          class="img-fluid resort-slides resort-slides-hidden more-img speacial-product-img"
          style="border-radius: 7px; width: 100%;">
        <img src="../../../../assets/img/home_resorts/image_4.jpg" alt=""
          class="img-fluid resort-slides resort-slides-hidden more-img speacial-product-img"
          style="border-radius: 7px; width: 100%;">
        <img src="../../../../assets/img/home_resorts/image_5.jpg" alt=""
          class="img-fluid resort-slides resort-slides-hidden more-img speacial-product-img"
          style="border-radius: 7px; width: 100%;">

      </a>
    </div>
    <div class="col-sm-12 col-md-6" id="padding-left">
      <h3 class="desktop-title  mt-remove"><span class="caps-title">Badriville Resort</span></h3>
      <p>
        Badriville Resort and Nature Camp - Badrinath
      </p>
      <ul class="list_order" *ngFor="let item of resortStayList; let i=index;">
        <li><span>{{i+1}}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{item.benefits}}</li>
      </ul>
      <button class="btn_1 mx-2 card-btns" (click)="resorts()">More
        details</button>
      <button class="btn_1 mx-2 card-btns" (click)="contactUs()">Contact
        Us</button>
    </div>
  </div>
</div>
