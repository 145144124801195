<div  style="padding-top: 60px;padding-bottom: 60px;">
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm-8">
          <div class="row map-responsive">

            <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Badriville%20Resort%20Nagini%20Hills%20Badrinath,%20near%20ISBT%20Badrinath,%20Joshimath,%20Uttarakhand%20246443+(My%20Business%20Name)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </iframe>
          </div>
          <div class="row map-responsive">
            <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Badriville%20Resort%20Homestay,%20Singh%20Dhar%20Talla,%20Chamoli,%20Joshimath,%20Uttarakhand%20246443+(My%20Busqwqiness%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </iframe>
          </div>
        </div>
        <hr class="divider-hr">
        <div class="col-sm-4">
          <div class="map-responsive" style="height: 100%;">
            <!-- <div class="tagembed-container tagembed-socialwall">
              <iframe frameborder="0" scrolling="yes" marginheight="0" marginwidth="0"
                src="https://widget.tagembed.com/9382?view" style="overflow-y: scroll;">
              </iframe>
            </div> -->
            
            <div class="tagembed-container tagembed-socialwall">
              <iframe frameborder="0" scrolling="yes" marginheight="0" marginwidth="0"
                src="https://widget.tagembed.com/26459?view" style="overflow-y: scroll;">
              </iframe>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- data-wall-id="9382"
  view-url="https://widget.tagembed.com/9382?view" -->
  <div class="container mbl-margin" style="padding-bottom: 20px;padding-top: 20px;">
    <div class="main_title">
      <h2><span>Get in Touch</span></h2>
      <!-- <p>Quisque at tortor a libero posuere laoreet vitae sed arcu. Curabitur consequat.</p> -->
    </div>
  </div>
</div>
