<div class="white_bg" style="padding-top: 30px;padding-bottom: 30px;">
  <div class="main_title">
    <h2><span>We </span>also Provide</h2>

  </div>
  <div class="container margin_15">
    <h3 class="mobile-title">Badriville Special <span>Herbal Tea</span></h3>
    <div class="row small-gutters categories_grid">
      <div class="col-sm-12 col-md-6 wow zoomIn" data-wow-delay="0.3s">
        <span class="more-img-padding" style="border-radius: 7px;">
          <img src="../../../assets/img/herbal_tea.jpg" alt="" class="img-fluid more-img speacial-product-img"
            style="border-radius: 7px; width: 100%;">
        </span>
      </div>
      <div class="col-sm-12 col-md-6" id="padding-left">
        <h3 class="desktop-title mt-remove">Badriville Special <span>Herbal Tea</span></h3>
        <p>
          <b>Ingredients :</b>
          Lemon Grass, Badri Tulsi, Tej Patta, Mint, Thyme, Rose, Daman, Choru, Burans, Timur ,
          Rudravanti.
        </p>
        <b>Benefits :</b>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-6" *ngFor="let item of spacilaProductList;">
            <ul class="list_ok" style="margin: 0 0 4px 0;">
              <li style="font-family: inherit;">{{item.benefits}}</li>
            </ul>
          </div>
        </div>
        <button class="btn_1 btn-bottom">Contact Us</button>
      </div>
    </div>
  </div>
</div>