<div id="preloader">
  <div class="sk-spinner sk-spinner-wave">
    <div class="sk-rect1"></div>
    <div class="sk-rect2"></div>
    <div class="sk-rect3"></div>
    <div class="sk-rect4"></div>
    <div class="sk-rect5"></div>
  </div>
</div>
<!-- End Preload -->

<div class="layer"></div>
<!-- Mobile menu overlay mask -->

<!-- Header================================================== -->
<header>
  <div id="top_line">
    <div class="container">
      <div class="row">
        <div class="col-6"><i class="icon-phone"></i><strong>
            <a href="tel://9412418725" class="phone" style="color:white;">(+91) 9412418725 </a>
            <a href="tel://8126281997" class="phone" style="color:white;">(+91) 8126281997 </a></strong>
        </div>
        <div class="col-6">
        </div>
      </div><!-- End row -->
    </div><!-- End container-->
  </div><!-- End top line-->

  <div class="container">
    <div class="row">
      <div class="col-3">
        <div id="logo_home">
          <h1><a routerLink="home" title="Badriville - Resort,Homestay,Nature Camp,Trekking and Touring">Badriville -
              Resort,Homestay,Nature Camp,Trekking and Touring</a></h1>
        </div>
      </div>
      <nav class="col-9">
        <a class="cmn-toggle-switch cmn-toggle-switch__htx open_close" routerLink=""
          (click)="showMobileMenu()"><span>Menu
            mobile</span></a>
        <div class="main-menu" [ngClass]="isShow ? 'show' : ''">
          <div id="header_menu">
            <img src="../../../../assets/img/logo/logo_sticky.png" width="160" height="34" alt="Badri ville resorts"
              data-retina="true">
          </div>
          <a href="#" class="open_close" id="close_in"><i class="icon_set_1_icon-77"></i></a>
          <ul class="show_normal">
            <li class="submenu">
              <a routerLink="home" class="show-submenu">Home</a>
            </li> 
            <li class="submenu">
              <a href="javascript:void(0);" (click)="showHotels()" class="show-submenu">Hotels <i
                  class="icon-down-open-mini"></i></a>
              <ul [ngClass]="isHotelShow ? 'show_normal' : ''">
                <li><a (click)="navigateUrl('resorts')" class="mouse-pointer">Badriville Resort</a></li>
                <li><a (click)="navigateUrl('homestay')" class="mouse-pointer">Badriville Homestay</a></li>
              </ul>
            </li>
            <li>
              <a href="javascript:void(0);"  (click)="toactivities()" [routerLink]="['./services']" routerLinkActive="router-link-active" class="show-submenu">Our Services <i
                  class="icon-down-open-mini"></i></a>
              <ul [ngClass]="isServiceShow ? 'show_normal' : ''">
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Spiritual Tour</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Yoga Camp at Himalaya</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Village Tour</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Nature Camping</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Trekking</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Mountain Biking</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Motorcycle Tour</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Skiing</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Wildlife Safari</a></li>
                <li><a (click)="navigateUrl('coming-soon')" class="mouse-pointer">Equipment Hire</a></li>
              </ul>
            </li>
            <li class="submenu">
              <!-- <a routerLink="" (click)="navigateUrl('coming-soon')" class="show-submenu">About Us</a> -->
            </li>
            <li class="submenu">
              <!-- (click)="navigateUrl('contact-us')" routerLink="" -->
              <a class="show-submenu" (click)="tofooter()" [routerLink]="['../footer/footer.component.html']" routerLinkActive="router-link-active" >Contact Us</a>
            </li>
            <li class="submenu fadeimg">
              <div id="fadeshow">
                <div class="social-icon">
                  <img src="../../../../assets/img/logo/kubera2.png" class="visible-img" width="80" height="60"
                    alt="Badri ville resorts" data-retina="true" style="border-radius: 10px;">
                  <img class="normal-social" src="https://img.icons8.com/material-rounded/25/ffffff/facebook-f--v2.png"
                    style="padding-right:10px"
                    (click)="socialMediaRoute('https://www.facebook.com/Badriville-Homestay-284952892041524')">
                  <img class="normal-social" src="https://img.icons8.com/ios/25/ffffff/instagram-new--v1.png"
                    (click)="socialMediaRoute('https://www.instagram.com/badriville')" />
                  <img class="sticky-social" src="https://img.icons8.com/material-rounded/25/000000/facebook-f--v2.png"
                    style="padding-right:10px"
                    (click)="socialMediaRoute('https://www.facebook.com/Badriville-Homestay-284952892041524')">
                  <img class="sticky-social"
                    src="https://img.icons8.com/material-outlined/25/000000/instagram-new--v2.png"
                    (click)="socialMediaRoute('https://www.instagram.com/badriville')" />
                </div>
              </div>
            </li>
            <li class="social-icon-float">

            </li>
          </ul>
        </div>
        <!-- End main-menu -->
      </nav>
    </div>
  </div><!-- container -->
</header>
<!-- End Header -->
