
<app-slider></app-slider>

<app-resort></app-resort>

<app-homestay></app-homestay>

<app-activity></app-activity>

<app-other-product></app-other-product>

<app-product></app-product>

<app-review></app-review>

<app-address></app-address>
