<!-- <section id="hero" class="background-image bg-img">
  <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
    <div class="intro_title error center">
      <div class="row">
        <div class="col-md-12">
          <div id="logo" class="img-align"><img
              src="https://firebasestorage.googleapis.com/v0/b/badriville-admin.appspot.com/o/Badriville2.png?alt=media&token=d8d4abbe-2336-4aeb-ad0b-d46d0b28097f"
              width="200" height="60" alt="Badriville"></div>
          <h3 class="animated fadeInDown text-align">Coming Soon.!</h3>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="parallax-window bg-img" data-parallax="scroll" data-natural-width="1400" data-natural-height="470">
  <div class="parallax-content-1 opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
    <div class="animated fadeInDown">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/badriville-admin.appspot.com/o/Badriville2.png?alt=media&token=d8d4abbe-2336-4aeb-ad0b-d46d0b28097f"
        width="200" height="60" alt="Badriville">
      <h1>Coming Soon</h1>
    </div>
  </div>
</section>
