<div class="white_bg" style="padding-top: 30px;padding-bottom: 30px;">
  <div class="container">
    <div class="row small-gutters categories_grid">
      <h3 class="mobile-title  mt-remove"><span class="caps-title">Badriville Homestay</span></h3>
      <div class="col-sm-12 col-md-6 wow zoomIn" data-wow-delay="0.3s">
        <a href="#" class="more-img-padding speacial-product-img" style="border-radius: 7px;">

          <img src="../../../../assets/img/home_stay/1.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/2.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/3.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/4.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/5.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/6.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/7.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/8.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
          <img src="../../../../assets/img/home_stay/9.jpg" alt=""
            class="img-fluid homestay-slides homestay-slides-hidden more-img speacial-product-img"
            style="border-radius: 7px; border-radius: 5px; width: 100%;">
        </a>
      </div>
      <div class="col-sm-12 col-md-6 padding-left" id="more-padding">
        <h3 class="desktop-title  mt-remove"><span class="caps-title">Badriville Homestay</span></h3>
        <p> Badriville Homestay - Joshimath </p>
        <ul class="list_order" *ngFor="let item of homeStayList; let i=index;">
          <li><span>{{i+1}}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{item.benefits}}</li>
        </ul>
        <button class="btn_1 mx-2 card-btns" (click)="homestay()">
          More details</button>
        <button class="btn_1 mx-2 card-btns" (click)="contactUs()">
          Contact Us</button>
      </div>
    </div>
  </div>
</div>