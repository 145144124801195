// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCu3zCEbfcFeYbZONfYkruRTrfUW2YF0-Y",
    authDomain: "badriville-dashboard.firebaseapp.com",
    databaseURL: "https://badriville-dashboard-default-rtdb.firebaseio.com",
    projectId: "badriville-dashboard",
    storageBucket: "badriville-dashboard.appspot.com",
    messagingSenderId: "412194973200",
    appId: "1:412194973200:web:e105be07be793fbc8fcc95",
    measurementId: "G-KKXJZS7V66"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
